<template>
  <router-view :key="key" v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
};
</script>

<style lang="scss"></style>

import * as types from "./mutation-types";
import { login, getMonth } from "@/api/login";
import { logout } from "@/api/logout";
import router from "@/router";
import storage from "@/utils/storage";
import { initFilter } from "@/api/rankingList";

export const logInAction = async ({ commit }, routerFlag = true) => {
  // vuex记录菜单
  const month = await getMonth();

  const {
    // lang roleStr 没用到
    data: { crowdList, role, menuList, brandCoop, defaultMenuId },
  } = await login();
  const findObjectByValue = (arr, val) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].id === val) {
        return arr[i].urls;
      }
      if (Array.isArray(arr[i].children)) {
        var found = findObjectByValue(arr[i].children, val);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };
  const result = defaultMenuId
    ? findObjectByValue(menuList, defaultMenuId)
    : null;
  if (result) {
    //如果defaultMenuId有值， 隐藏掉menu首页
    if (menuList[0].id === 100) {
      menuList.shift();
    }
  }
  let targetCrowd = crowdList[0];
  commit(types.SET_CROWDLIST, crowdList);
  commit(types.SET_TARGETCROWD, targetCrowd);
  commit(types.SET_DASHBOARDCROWD, targetCrowd);
  commit(types.SET_MONTH, month.data);
  commit(types.SET_ROLE, role);
  commit(types.SET_MENULIST, menuList);
  commit(types.SET_BRANDCOOP, brandCoop);
  commit(types.SET_ISSHOWLOGINDIALOG, false);
  // session记录菜单
  let tempArr = [];
  menuList.forEach(({ children, urls }) => {
    if (children.length) {
      children.forEach(({ urls }) => {
        tempArr.push(urls);
      });
    } else {
      tempArr.push(urls);
    }
  });
  storage.setItem("menuList", tempArr);
  // 获取平台
  const { data } = await initFilter();
  data.forEach((v) => {
    if (v.kuaishou === null) {
      v.kuaishou = [];
    }
  });
  const tempObj = {};
  const tempSite = data.map(({ label, value, category }) => {
    tempObj[value] = category;
    return {
      label,
      value,
    };
  });
  const cascaderSite = data.map(({ label, value, category }) => {
    let children = [];
    if (category) {
      children = category.map(({ name }) => {
        return {
          label: name,
          value: name,
        };
      });
    }
    return {
      label,
      value,
      children,
      disabled: true,
    };
  });
  commit(types.SET_CLASSIFYDATA, tempObj);
  commit(types.SET_SITES, tempSite);
  commit(types.SET_SITEVALUE, tempSite[0].value);
  commit(types.SET_COLSITEVALUE, tempSite[0].value);
  commit(types.SET_CASCADERSITE, cascaderSite);
  commit(types.SET_AMATEURSITES, tempSite);
  // 路由跳转

  const skip = () => {
    if (menuList[0].children.length !== 0) {
      router.push(menuList[0].children[0].urls);
    } else {
      router.push(menuList[0].urls);
    }
  };
  if (routerFlag) {
    const result = defaultMenuId
      ? findObjectByValue(menuList, defaultMenuId)
      : null;
    if (result) {
      //如果defaultMenuId有值， 隐藏掉menu首页
      if (menuList[0].id === 100) {
        menuList.shift();
      }
      router.push(result);
    } else {
      skip();
    }
  }
};

export const logOutAction = async () => {
  await logout();
  storage.clearAll();
  // todo：vuex数据初始化
  router.push("/homeIndex");
};

<template>
  <el-dialog
    v-model="value"
    :before-close="close"
    :close-on-click-modal="false"
  >
    <div class="header">
      <img :src="logoSrc" />
    </div>
    <div class="content">
      <h5 class="intro">产品简介:</h5>
      <p>
        omniVoice是基于久谦专有数据和深度商业分析的KOL投放甄选工具。omniVoice帮助品牌主、广告服务商、及其他媒体市场机构量化KOL触达效率、分析KOL用户画像、甄别最优投放组合,
        从而优化ROI。同时可与客户现有目标用户(TA)打通匹配,
        提供针对性投放决策建议。
      </p>
      <h5 class="intro">产品优势:</h5>
      <ul>
        <li>• 真实追踪用户线上KOL账号、文章浏览行为</li>
        <li>• 独家提供KOL浏览用户特征画像 (人口学、兴趣偏好、内容偏好等)</li>
        <li>• 海量覆盖运营商3G/4G用户</li>
        <li>
          • 全面支持多类型打通匹配 (IMEI/ IDFA/ 加密手机号等,
          打通方式高度合规并经多行业客户验证)
        </li>
      </ul>
      <h5 class="intro">专有数据:</h5>
      <ul>
        <li>• 运营商URL日志: 联通和电信全国5亿手机账户 (覆盖40% 3G/4G用户)</li>
        <li>• 爬虫字典: 8000+微信KOL, 覆盖各类型头部及中部KOL</li>
        <li>
          • 结构化数据: 通过久谦专有技术, 关联KOL文章与个人访问行为,
          真实追踪用户线上行为
        </li>
      </ul>
      <ul>
        <li>omniVoice v1.00（2018年5月）</li>
        <li>版权所有：&copy; {{ text }}</li>
        <li>
          除非另外声明，本产品的使用须遵守久谦技术服务有限公司最终用户协议的条款。
        </li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      logoSrc: require("./omniVoice.png"),
    };
  },
  props: ["modelValue"],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    text () {
      let latestYear = new Date().getFullYear()
      return `版权所有：© 2019 - ${latestYear} 久远谦长（北京）技术服务有限公司`
    }
  },
  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  padding-bottom: 10px;
  border-bottom: 1px solid #aaaaaa;
}
.content {
  line-height: 50px;

  .intro {
    font-weight: bold;
    font-size: 14px;
    margin: 10px 0 5px;
  }
}
</style>

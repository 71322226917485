import moment from "moment";

/**
 千分位用逗号分隔，只能传数字
 */
export function sliceDecimal(num) {
  if (num) {
    if (num === "" || isNaN(num)) {
      return num;
    }
    num = num + "";
    let sign = num.indexOf("-") > 0 ? "-" : "";
    let cents = num.indexOf(".") > 0 ? num.substr(num.indexOf(".")) : "";
    cents = cents.length > 1 ? cents : "";
    num = num.indexOf(".") > 0 ? num.substring(0, num.indexOf(".")) : num;
    if (cents !== "") {
      if(cents.length > 3) {
        cents = cents.substring(0, 3);
      }
      if (num.length > 1 && num.substr(0, 1) === "0") {
        return "Not a Number ! ";
      }
    } else {
      if (num.length > 1 && num.substr(0, 1) === "0") {
        return "Not a Number ! ";
      }
    }
    for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
      num =
          num.substring(0, num.length - (4 * i + 3)) +
          "," +
          num.substring(num.length - (4 * i + 3));
    }
    return sign + num + cents;
  } else {
    if (num === null) {
      return "--";
    }
    return 0;
  }
}

export function platformToZn(platform) {
  switch (platform) {
    case "wechat":
      return "微信";
    case "weibo":
      return "微博";
    case "douyin":
      return "抖音";
    case "xiaohongshu":
      return "小红书";
    case "bilibili":
      return "哔哩哔哩";
    case "kuaishou":
      return "快手";
    default:
      return platform;
  }
}

export function postTypeToZn(postType) {
  switch (postType) {
    case "multi_top_price":
      return "一推";
    case "multi_second_price":
      return "二推";
    case "tweet_price":
      return "直发";
    case "retweet_price":
      return "转发";
    case "video_21_60s_price":
      return "视频21-60s";
    case "video_1_20s_price":
      return "视频1-20s";
    case "video_60s_price":
      return "视频60s以上";
    case "image_price":
      return "图文笔记";
    case "video_price":
      return "视频笔记";
    case "original_release_price":
      return "视频定制";
    case "placement_in_release_price":
      return "视频植入";
    case "original_price":
      return "原创视频";
  }
}

export function urlToPlatform(url) {
  if (url === "") {
    return null;
  }
  if (!url.includes("https://") && !url.includes("http://")) {
    return null;
  }
  // b站测试 b站连接只会带有bilibili的域名
  if (
    url.includes("bilibili") ||
    url.includes("b23.tv") ||
    url.includes("www.bilibili.com")
  ) {
    return "bilibili";
  }
  // wechat文章链接
  if (
    url.includes("mp.weixin.qq.com") ||
    url.includes("weixin") ||
    url.includes("qq")
  ) {
    return "wechat";
  }
  // weibo链接
  if (url.includes("weibo.com") || url.includes("m.weibo.cn")) {
    return "weibo";
  }
  // xhs链接
  if (url.includes("www.xiaohongshu.com") || url.includes("xhslink.com")) {
    return "xiaohongshu";
  }
  // douyin
  if (
    url.includes("www.douyin.com") ||
    url.includes("www.amemv.com") ||
    url.includes("v.douyin.com") ||
    url.includes("www.iesdouyin.com")
  ) {
    return "douyin";
  }

  // kuaishou
  if (
    url.includes("kuaishou") ||
    url.includes("chenzhongtech.com") ||
    url.includes("v.kuaishou.com") ||
    url.includes("gifshow.com")
  ) {
    return "kuaishou";
  }
  return null;
}

export function formatNull(data) {
  data.forEach((v) => {
    Object.keys(v).forEach((k) => {
      if (v[k] instanceof Array) {
        this.formatNull(v[k]);
      } else if (v[k] === null) {
        v[k] = "--";
      }
    });
  });
}

export function formatMoneyNum(num) {
  return num / 100;
}

export function btoa(str) {
  return window.btoa(window.encodeURIComponent(str));
}

export function atob(str) {
  return window.decodeURIComponent(window.atob(str));
}

import sha from "sha256";

export function sha256(str) {
  return sha(str);
}

export function timeStampToDate(timestamp = new Date(), connector = "") {
  const date = new Date(timestamp);
  const Y = date.getFullYear();
  const M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return Y + connector + M + connector + D;
}

export function setEndDate(time) {
  let nowTime = moment().format("yyyy-MM");
  let choseTime = moment(time).format("yyyy-MM");
  if (nowTime.split("-")[1] === choseTime.split("-")[1]) {
    return nowTime;
  } else {
    return moment(time).endOf("month").format("yyyy-MM");
  }
}

export function setStartDate(time) {
  return moment(time).startOf("month").format("yyyy-MM");
}

// 获取月份区间内的所有日期 1月到3月 2022-01-01 ~ 2022-03-01
// params: 2022-01 2022-03
// return [2022-01-01 ...... 2022-03-31]
export function getAllDate(start, end) {
  let dateArr = [];
  let startArr = start.split("-");
  let endArr = end.split("-");
  let db = new Date();
  db.setFullYear(startArr[0], startArr[1] - 1, startArr[2]);
  let de = new Date();
  de.setFullYear(endArr[0], endArr[1] - 1, endArr[2]);
  let stampDb = db.getTime();
  let stampDe = de.getTime();
  let stamp = stampDb;
  const oneDay = 24 * 60 * 60 * 1000;
  for (stamp; stamp <= stampDe; stamp += oneDay) {
    dateArr.push(timeStampToDate(stamp, "-"));
  }
  return dateArr;
}

// // 获取月份第一天
// function getMonthFirstday(date) {
//   return moment(date).startOf("month").format("YYYY-MM-DD");
// }
// // 获取月份最后一天
// function getMonthLastday(date) {
//   return moment(date).endOf("month").format("YYYY-MM-DD");
// }

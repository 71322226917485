<template>
  <a-config-provider :locale="zhCN">
    <el-container class="mainContainer">
      <el-header height="90px">
        <header-com :key="key"></header-com>
      </el-header>
      <el-main>
        <container />
      </el-main>
      <el-footer height="30px">
        <common-footer />
      </el-footer>
    </el-container>
  </a-config-provider>
</template>

<script>
import headerCom from "./header/header";
import container from "./main/main";
import CommonFooter from "@/components/common/footer/footer";
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "index",
  data() {
    return {
      timer: "",
      time: 3600000,
      month: {
        month: "201908",
      },
      zhCN,
    };
  },
  mounted() {
    this.timeOut();
    this.watchTimeOut();
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {
    timeOut() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        location.reload();
      }, this.time);
    },
    watchTimeOut() {
      window.top.document.onmousemove = () => {
        this.timeOut();
      };
      window.top.document.onkeyup = () => {
        this.timeOut();
      };
    },
  },
  components: {
    CommonFooter,
    headerCom,
    container,
  },
};
</script>

<style scoped lang="scss">
.mainContainer {
  height: 100%;
  background:#EFF3F4 url("../../assets/images/Background.png") no-repeat top;
  background-size:100% 400px;
}
.el-header {
  background-color: transparent;
  color: $color-text;
}

.el-main {
  color: $color-text;
  //width: 1440px;
  margin: 0 auto;
  width:100%;
  padding:20px 50px;
}

.el-footer {
  // background-color: $color-dialog-background;
  color: $color-text-l;
  line-height: 30px;
  text-align: center;
  // border-top: 1px solid #ddd;
  z-index: 9999;
}
</style>

<template>
  <div>跳转中...</div>
</template>

<script>
import { sendCode } from "@/api/redirect";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "redirect",
  data() {
    return {
      code: "",
    };
  },
  mounted() {
    this.judgeCode();
  },
  methods: {
    ...mapMutations({
      setToken: "SET_TOKEN",
      setRefreshToken: "SET_REFRESH_TOKEN",
      setUpdateText: "SET_UPDATETEXT",
      setIsLoginDialog: "SET_ISSHOWLOGINDIALOG",
    }),
    ...mapActions(["logInAction"]),
    judgeCode() {
      let searchURL = window.location.search;
      searchURL = searchURL.substring(1, searchURL.length);
      this.code = searchURL.split("&")[0].split("=")[1];
      let param = {
        code: this.code,
      };
      sendCode(param).then((res) => {
        /*
         *  根据返回
         *  1.已经绑定过账号，返回token，清空wxcode，跳转到首页
         *  2.跳转到账号密码登录页（怎么确定显示二维码还是form，根据wxcode）
         *  message 10 获取微信信息失败
         * */
        this.setRefreshToken("");
        this.setIsLoginDialog(false);
        console.log(res);
        if (res.token) {
          this.setToken(res.token);
          this.logInAction();
        } else if (res.refresh_token) {
          this.setRefreshToken(res.refresh_token);
          this.setIsLoginDialog(true);
          this.$router.push("/homeIndex");
        } else if (res.message) {
          this.setRefreshToken({ message: res.message });
          this.setIsLoginDialog(true);
          this.$router.push("/homeIndex");
        } else if (res.displayText) {
          // 新增（升级、维护）状态展示对话框
          this.setUpdateText(res.displayText);
          this.$router.push("/updatePage");
        } else {
          // 未知错误
          this.$router.push("/homeIndex");
        }
      });
    },
  },
};
</script>

<style scoped></style>

<template>
  <redirect v-if="isRedirect" />
  <home v-else />
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getToken, getMonth } from "@/api/login";

import Redirect from "@/views/Redirect";
import Home from "@/views/Home";

import { useStore } from "vuex";
import sha from "sha256";

const store = useStore();

const route = useRoute();
const router = useRouter();

const isRedirect = computed(() => route.name === "Redirect");

onMounted(() => {
  window.addEventListener(
    "message",
    async (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data && data.type === "init" && data.data.token) {
          let res = await getTokenFunc();
          if (res.code === 0) {
            store.commit("SET_TOKEN", res.data);
          }
          setTimeout(() => {
            router.push({ path: "/homepage" });
          }, 500);
        } else if (data && data.type === "refresh_token" && data.data.token) {
          getMonthFunc();
        }
      } catch (e) {
      }
    },
    false
  );
});
const getMonthFunc = async () => {
  await getMonth();
};
const getTokenFunc = async () => {
  const username = "xiaoxuan";
  const password = sha("Xiaoxuan2022!");
  let data = await getToken({
    username,
    password,
  });
  return data;
};
</script>

<style lang="scss">
body {
  .el-popper {
    max-width: 450px;
  }

  .el-card {
    .el-card__header,
    .el-card__body {
      padding: 10px 20px;
    }
  }
}
</style>

import { getKolDetails } from "@/api/rankingList";
import { getDetailsTrend, getArticleList, getPersona } from "@/api/kolDetail";
import { formatRes } from "../formatDataTool";
export default {
  namespaced: true,
  state: () => ({
    // 基础数据
    kolName: "",
    kolMkolId: "",
    site: "",
    // 趋势数据 & 文章数据
    detailData: {},
    articleColor: ["#113491", "#bfd9ef", "#65a6ea"],
    trendData: { xAxis: {}, series: [], legend: { data: [] } },
    hotArticles: {},
    trendLoading: true,
    hotArticlesLoading: true,
    // 人群数据
    userProfileData: {},
    personaLoading: true,
    // 合作数据:
  }),
  mutations: {
    setKolName(state, payload) {
      state.kolName = payload;
    },
    setDetailData(state, payload) {
      state.detailData = payload;
    },
    setTrend(state, payload) {
      state.trendData = payload;
    },
    setKolMkolId(state, payload) {
      state.kolMkolId = payload;
    },
    setSite(state, payload) {
      state.site = payload;
    },
    setHotArticles(state, payload) {
      state.hotArticles = payload;
    },
    setTrendLoading(state, payload) {
      state.trendLoading = payload;
    },
    setHotArticlesLoading(state, payload) {
      state.hotArticlesLoading = payload;
    },
    setUserProfileData(state, payload) {
      state.userProfileData = payload;
    },
    setPersonaLoading(state, payload) {
      state.personaLoading = payload;
    },
  },
  actions: {
    async getKolDetails({ commit }, params) {
      return await getKolDetails(params)
        .then(async (res) => {
          if (res.code === 0) {
            commit("setDetailData", res.data);
            commit("setKolName", res.data.kolName);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDetailsTrend({ commit }, params) {
      commit("setTrendLoading", true);
      return await getDetailsTrend(params)
        .then(async (res) => {
          if (res.code === 0) {
            commit("setTrend", res.data.articleHistory);
            commit("setTrendLoading", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getArticleList({ commit }, params) {
      commit("setHotArticlesLoading", true);
      return await getArticleList(params)
        .then(async (res) => {
          commit("setHotArticles", res.data);
          commit("setHotArticlesLoading", false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPersona({ commit }, params) {
      commit("setPersonaLoading", true);
      return await getPersona(params)
        .then(async (res) => {
          console.log(res)
          if (res.code === 0) {
            commit("setUserProfileData", formatRes(res.data));
            commit("setPersonaLoading", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

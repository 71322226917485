<template>
  <div class="footer">
    {{ text }}
    <a class="footer_a" href="http://www.beian.gov.cn/" target="_blank">
      {{ beianNumer }}
    </a>
  </div>
</template>

<script>
export default {
  name: "commonFooter",
  data() {
    return {
      beianNumer: "京ICP备12034958号-1",
    };
  },
  computed: {
    text () {
      let latestYear = new Date().getFullYear()
      return `版权所有：© 2019 - ${latestYear} 久远谦长（北京）技术服务有限公司`
    }
  },
};
</script>

<style scoped lang="scss">
.footer {
  font-size: 14px;
  color: #a5a5a5;
  margin-bottom: 10px;

  .footer_a {
    position: relative;
    left: 0;
    top: -1px;
    margin-left: 5px;
    font-family: "Microsoft YaHei";
  }
}
</style>

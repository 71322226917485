export const SET_TOKEN = "SET_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_UPDATETEXT = "SET_UPDATETEXT";
export const SET_CROWDLIST = "SET_CROWDLIST";
export const SET_TARGETCROWD = "SET_TARGETCROWD";
export const SET_AMATEURSITES = "SET_AMATEURSITES";
export const SET_MONTH = "SET_MONTH";
export const SET_ROLE = "SET_ROLE";
export const SET_BRANDCOOP = "SET_BRANDCOOP";
export const SET_MENULIST = "SET_MENULIST";
export const SET_SITES = "SET_SITES";
export const SET_SITEVALUE = "SET_SITEVALUE";
export const SET_CASCADERSITE = "SET_CASCADERSITE";
export const SET_ISDISABLED = "SET_ISDISABLED";
export const SET_FILTERINFO = "SET_FILTERINFO";

export const SET_ISEXPAND = "SET_ISEXPAND";
export const SET_CLASSIFYDATA = "SET_CLASSIFYDATA";

export const SET_ISHIGHLIGHT = "SET_ISHIGHLIGHT";
export const SET_ORDERRULE = "SET_ORDERRULE";
export const SET_LISTSORTLABEL = "SET_LISTSORTLABEL";
export const SET_LISTVERSION = "SET_LISTVERSION";
// 常用达人收藏的数据
export const SET_COLTARGETCROWD = "SET_COLTARGETCROWD";
export const SET_COLORDERRULE = "SET_COLORDERRULE";
export const SET_COLSITES = "SET_COLSITES";
export const SET_COLSITEVALUE = "SET_COLSITEVALUE";

export const SET_KEEP_ALIVE = "SET_KEEP_ALIVE";
export const SET_COLDONE = "SET_COLDONE";
export const SET_INITDATAFLAG = "SET_INITDATAFLAG";
export const SET_ISCATCHPAGE = "SET_ISCATCHPAGE";
export const SET_COLORDERFILED = "SET_COLORDERFILED";
export const SET_CREATECAMPAIGNORDERFILED = "SET_CREATECAMPAIGNORDERFILED";
export const SET_CAMPAIGNINFOORDERFILED = "SET_CAMPAIGNINFOORDERFILED";
// campaignKolNum
export const SET_CAMPAIGNKOLNUM = "SET_CAMPAIGNKOLNUM";
// sovPageSave
export const SET_SOVPAGESAVE = "SET_SOVPAGESAVE";
export const SET_SOVCATEPAGESAVE = "SET_SOVCATEPAGESAVE";
export const SET_PRODUCTPAGESAVE = "SET_PRODUCTPAGESAVE";
// 切换 isShowLoginDialog
export const SET_ISSHOWLOGINDIALOG = "SET_ISSHOWLOGINDIALOG";

// detailsToRankList
export const SET_DETAILSTORANKLIST = "SET_DETAILSTORANKLIST";

// collectionSite SET_COLLECTIONSITE
export const SET_COLLECTIONSITE = "SET_COLLECTIONSITE";

// isAddClass SET_ISADDCLASS
export const SET_ISADDCLASS = "SET_ISADDCLASS";

// SET_DIALOGCLOSE
export const SET_DIALOGCLOSE = "SET_DIALOGCLOSE";

// SET_DASHBOARDCROWD
export const SET_DASHBOARDCROWD = "SET_DASHBOARDCROWD";

// 全局loading
export const SET_AXIOSLOADING = "SET_AXIOSLOADING";

//base64List
export const SET_BASE64LIST = "SET_BASE64LIST";

// //   // 单品牌人群
//   brandSeg: "32_1",
//   // 多品牌人群
//   brandsSeg: "32_1",
//   // 单产品人群
//   productSeg: "32_1",
//   // 多产品人群
//   productsSeg: "32_1",
//   SET_BRANDSEG SET_BRANDSSEG SET_PRODUCTSEG SET_PRODUCTSSEG
export const SET_BRANDSEG = "SET_BRANDSEG";
export const SET_BRANDSSEG = "SET_BRANDSSEG";
export const SET_PRODUCTSEG = "SET_PRODUCTSEG";
export const SET_PRODUCTSSEG = "SET_PRODUCTSSEG";

<template>
  <div class="breadWrap">
    <i class="el-icon-menu breadIcon"></i>
    <el-breadcrumb class="elBreadCrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item, index) in data"
        :key="index"
        :to="item.to"
      >
        <span
          :class="
            (item.name === '达人榜单' ||
              item.name === activeName ||
              item.name === '追加KOL') &&
            AddClass &&
            isAddClass
              ? 'backToRanklist'
              : ''
          "
          @click="backToRankList(item.name)"
          >{{ item.name }}</span
        >
        <!-- </template> -->
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      breadData: {
        Homepage: [{ name: "首页", to: "/homepage" }],
        Home: [{ name: "首页", to: "/homeIndex" }],
        RankingListV2: [{ name: "榜单", to: "/rankingListV2" }],
        Admin: [{ name: "操作员管理", to: "/admin" }],
        Manage: [
          { name: "榜单", to: "/rankingListV2" },
          { name: "人群管理", to: "/manage" },
        ],
        CampaignInsight: [
          { name: "声量", to: "" },
          { name: "社媒活动洞察", to: "/campaignInsight" },
        ],
        InsightCompare: [
          { name: "投放", to: "" },
          { name: "社媒活动洞察", to: "/campaignInsight" },
          { name: "投放对比", to: "/insightCompare" },
        ],
        InsightCalendar: [
          { name: "投放", to: "" },
          { name: "社媒活动洞察", to: "/campaignInsight" },
          { name: "投放日历", to: "/insightCalendar" },
        ],
        InsightDetail: [
          { name: "投放", to: "" },
          { name: "社媒活动洞察", to: "/campaignInsight" },
          { name: "活动详情", to: "/insightDetail" },
        ],
        Quote: [
          { name: "投放", to: "" },
          { name: "报价管理", to: "/quote" },
        ],
        QuoteDetail: [
          { name: "投放", to: "" },
          { name: "报价管理", to: "/quote" },
          { name: "详情", to: "/quoteDetail" },
        ],
        Agency: [
          { name: "投放", to: "" },
          { name: "供应商管理", to: "/agency" },
        ],
        DashBoard: [
          { name: "投放", to: "" },
          { name: "Dashboard", to: "/dashboard" },
        ],
        dashboardTrace: [
          { name: "投放", to: "" },
          { name: "活动监测Dashboard", to: "/dashboardTrace" },
        ],
        MKolIdConversion: [
          { name: "工具", to: "" },
          { name: "m_kolid转换", to: "/mkolidConversion" },
        ],
        DownLoadCrowd: [
          { name: "工具", to: "" },
          { name: "人群下载", to: "/loadCrowd" },
        ],
        KolCollection: [
          { name: "投放", to: "" },
          { name: "常用达人", to: "/kolCollection" },
        ],
        CollectDetail: [
          { name: "投放", to: "" },
          { name: "常用达人", to: "/kolCollection" },
          {
            name: this.$storage.getItem("collectionName"),
            to: "/collectDetail",
          },
        ],
        CampaignManage: [
          { name: "投放", to: "" },
          { name: "活动管理", to: "/campaign" },
        ],
        campaignTrace: [
          { name: "投放", to: "" },
          { name: "活动监测", to: "/campaignTrace" },
        ],
        CampaignInfo: [
          { name: "投放", to: "/campaign" },
          { name: "活动管理", to: "/campaign" },
          { name: "活动详情", to: "/campaignInfo" },
        ],
        CampaignReport: [
          { name: "投放", to: "" },
          { name: "活动管理", to: "/campaign" },
          { name: "活动评估", to: "/campaignReport" },
        ],
        CampaignCompareTrace: [
          { name: "投放", to: "" },
          { name: "活动监测", to: "/campaignTrace" },
          { name: "多活动对比", to: "/campaignInfoTrace" },
        ],
        CampaignCompare: [
          { name: "投放", to: "" },
          { name: "活动策划", to: "/campaign" },
          { name: "多活动对比", to: "/campaignCompare" },
        ],
        CreateCampaign: [
          { name: "投放", to: "" },
          { name: "活动管理", to: "/campaign" },
          { name: "创建活动", to: "/createCampaign" },
        ],
        AdditionalKol: [
          { name: "投放", to: "" },
          { name: "追加KOL", to: "/additionalKol" },
        ],
        AddKol: [
          { name: "投放", to: "" },
          { name: "添加KOL", to: "/addKol" },
        ],
        sovBrandList: [
          { name: "声量", to: "" },
          { name: "品牌榜单", to: "/brandRankingList" },
        ],
        sovBrandCompare: [
          { name: "声量", to: "" },
          { name: "品牌榜单", to: "/brandRankingList" },
          { name: "多品牌对比", to: "" },
        ],
        sovProductList: [
          { name: "声量", to: "" },
          { name: "产品榜单", to: "/productRankingList" },
        ],
        sovProductCompare: [
          { name: "声量", to: "" },
          { name: "产品榜单", to: "/productRankingList" },
          { name: "多产品对比", to: "/sovProductCompare" },
        ],
        sovCategoryList: [
          { name: "声量", to: "" },
          { name: "品类榜单", to: "/categoryRankingList" },
        ],
        sovCategory: [
          { name: "声量", to: "" },
          { name: "品类榜单", to: "/categoryRankingList" },
          { name: "品类详情", to: "" },
        ],
        sovCategoryCompare: [
          { name: "声量", to: "" },
          { name: "品类榜单", to: "/categoryRankingList" },
          { name: "多品类对比", to: "/sovCategoryCompare" },
        ],
        CosmeticDashboard: [
          { name: "美妆", to: "" },
          { name: "产品宣称 Dashboard", to: "/cosmeticDashboard" },
        ],

        TrendTracking: [
          { name: "美妆", to: "" },
          { name: "趋势追踪", to: "/trendTracking" },
        ],
        TrendTrackingDetails: [
          { name: "美妆", to: "" },
          { name: "趋势追踪", to: "/trendTracking" },
          { name: "详情", to: "" },
        ],
        ProductRepo: [
          { name: "美妆", to: "" },
          { name: "产品库", to: "/productRepo" },
        ],
        ProductClaim: [
          { name: "美妆", to: "" },
          { name: "产品库", to: "/productRepo" },
          { name: "产品对比", to: "" },
        ],
        ChanelDashboard: [
          { name: "CHANEL", to: "" },
          { name: "Dashboard", to: "/chanelDashboard" },
        ],
        //
        DownloadDataTool: [
          { name: "工具", to: "" },
          { name: "品牌/产品数据下载", to: "" },
        ],
        HcDataDownload: [
          { name: "工具", to: "" },
          { name: "后测数据筛选下载", to: "" },
        ],
        HcCreatingTasks: [
          { name: "工具", to: "" },
          { name: "后测数据筛选下载", to: "/hcDataDownload" },
          { name: "创建任务", to: "/hcCreatingTasks" },
        ],

        LogAdmin: [{ name: "日志管理", to: "/logAdmin" }],
        AnalysisReport: [
          {
            name: "声量",
            to: "",
          },
          {
            name: "舆情分析报告",
            to: "",
          },
        ],
      },
      AddClass: false,
      activeName: this.$storage.getItem("activeName"),
    };
  },
  computed: {
    ...mapGetters(["isAddClass"]),
    data() {
      const routeName = this.$route.name;
      const activeName = this.$storage.getItem("activeName");
      const checkActiveName = this.$storage.getItem("checkActiveName");
      const kolName = this.$storage.getItem("kolName");
      const returnPath = this.$storage.getItem("returnPath");
      const campaignName = this.$storage.getItem("campaignName");
      const returnCampaignPath = this.$storage.getItem("returnCampaignPath");
      if (routeName === "RankingListV2") {
        console.log(kolName);
        if (this.isAddClass) {
          return [
            {
              name: "榜单",
              to: "",
            },
            {
              name: "达人榜单",
              to: "",
            },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            {
              name: "榜单",
              to: "",
            },
            {
              name: "达人榜单",
              to: "/rankingListV2",
            }];
        }
      }
      if (routeName === "AmateurRankingList") {
        if (this.isAddClass) {
          return [
            {
              name: "榜单",
              to: "",
            },
            {
              name: "黑马榜单",
              to: "",
            },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            {
              name: "榜单",
              to: "",
            },
            {
              name: "黑马榜单",
              to: "/amateurRankingList",
            }];
        }
      }
      if (routeName === "CollectDetail") {
        if (this.isAddClass) {
          return [
            { name: "投放", to: "" },
            { name: "常用达人", to: "/kolCollection" },
            {
              name: activeName,
              to: "",
            },
            { name: kolName },
          ];
        } else {
          return [
            { name: "投放", to: "" },
            { name: "常用达人", to: "/kolCollection" },
            {
              name: activeName,
              to: "",
            },
          ];
        }
      }
      if (routeName === "AdditionalKol") {
        if (this.isAddClass) {
          return [
            { name: "投放", to: "" },
            { name: "活动管理", to: "/campaign" },
            { name: campaignName, to: returnCampaignPath },
            { name: "追加KOL", to: "" },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            { name: "投放", to: "" },
            { name: "活动管理", to: "/campaign" },
            { name: campaignName, to: returnCampaignPath },
            { name: "追加kol", to: "" },
          ];
        }
      }
      if (routeName === "AdditionalKolTrace") {
        if (this.isAddClass) {
          return [
            { name: "投放", to: "" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: campaignName, to: returnCampaignPath },
            { name: "追加KOL", to: "" },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            { name: "投放", to: "" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: campaignName, to: returnCampaignPath },
            { name: "追加kol", to: "" },
          ];
        }
      }
      if (routeName === "CreateCampaign") {
        if (this.isAddClass) {
          return [
            { name: "投放", to: "" },
            { name: "活动管理", to: "/campaign" },
            { name: "创建活动", to: "/createCampaign" },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            { name: "投放", to: "" },
            { name: "活动管理", to: "/campaign" },
            { name: "创建活动", to: "/createCampaign" },
          ];
        }
      }
      if (routeName === "CreateCampaignTrace") {
        if (this.isAddClass) {
          return [
            { name: "投放", to: "" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: "创建活动", to: "/createCampaignTrace" },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            { name: "投放", to: "" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: "创建活动", to: "/createCampaignTrace" },
          ];
        }
      }
      if (routeName === "KolDetails") {
        if (returnPath.includes("/collectDetail")) {
          return [
            { name: "投放", to: "" },
            { name: "常用达人", to: "/kolCollection" },
            {
              name: activeName,
              to: returnPath,
            },
            { name: kolName, to: "" },
          ];
        } else if (returnPath.includes("/additionalKol")) {
          return [
            { name: "投放", to: "" },
            { name: "活动管理", to: "/kolCollection" },
            { name: activeName, to: returnPath },
            { name: "追加KOL", to: "" },
            { name: kolName, to: "" },
          ];
        } else {
          return [
            {
              name: "榜单",
              to: returnPath,
              params: { platform: "eduardo" },
            },
            { name: kolName, to: "" },
          ];
        }
      }
      if (routeName === "AddKol") {
        const query = this.$route.query;
        // 如何区分是从收藏还是活动添加
        return [
          { name: "投放", to: "" },
          {
            name: activeName,
            to: { name: "CollectDetail", query },
          },
          { name: "添加KOL", to: "/addKol" },
        ];
      }
      if (routeName === "CampaignInfo") {
        console.log(checkActiveName, activeName);
        if (checkActiveName !== activeName) {
          return [
            { name: "投放", to: "/campaign" },
            { name: "活动管理", to: "/campaign" },
            { name: activeName, to: "/campaignInfo" },
          ];
        } else {
          return [
            { name: "投放", to: "/campaign" },
            { name: "活动管理", to: "/campaign" },
            { name: activeName, to: "/campaignInfo" },
          ];
        }
      }
      if (routeName === "CampaignInfoTrace") {
        if (checkActiveName !== activeName) {
          return [
            { name: "投放", to: "/campaign" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: activeName, to: "/campaignInfoTrace" },
          ];
        } else {
          return [
            { name: "投放", to: "/campaign" },
            { name: "活动监测", to: "/campaignTrace" },
            { name: activeName, to: "/campaignInfoTrace" },
          ];
        }
      }
      if (routeName === "CampaignReport") {
        const query = this.$route.query;
        return [
          { name: "投放", to: "" },
          { name: "活动管理", to: "/campaign" },
          { name: activeName, to: { name: "CampaignInfo", query } },
          { name: "活动评估", to: "/campaignReport" },
        ];
      }
      if (routeName === "CampaignReportTrace") {
        const query = this.$route.query;
        return [
          { name: "投放", to: "" },
          { name: "活动监测", to: "/campaignTrace" },
          { name: activeName, to: { name: "CampaignInfoTrace", query } },
          { name: "活动评估", to: {name:"CampaignReportTrace",query} },
        ];
      }
      if (routeName === "NegativeReport") {
        const query = this.$route.query;
        console.log(query)
        return [
          { name: "投放", to: "" },
          { name: "活动监测", to: "/campaignTrace" },
          { name: activeName, to: { name: "CampaignInfoTrace", query:{id:query.id} } },
          { name: "活动评估", to: { name: "CampaignReportTrace", query:{id:query.id} } },
          { name: "负评报告", to: {name:"NegativeReport",query} },
        ];
      }
      if (routeName === "QuoteDetail") {
        return [
          { name: "投放", to: "" },
          { name: "报价管理", to: "/quote" },
          { name: activeName, to: "/quoteDetail" },
        ];
      }
      if (routeName === "sovBrand") {
        const query = decodeURI(this.$route.query.brand);
        return [
          { name: "声量", to: "" },
          { name: "品牌榜单", to: "/brandRankingList" },
          { name: query, to: "" },
        ];
      }
      if (routeName === "sovProduct") {
        const query = decodeURI(this.$route.query.product);
        return [
          { name: "声量", to: "" },
          { name: "产品榜单", to: "/productRankingList" },
          { name: query, to: "" },
        ];
      }
      return this.breadData[routeName];
    },
  },
  methods: {
    ...mapMutations({
      setIsAddClass: "SET_ISADDCLASS",
      setDialogClose: "SET_DIALOGCLOSE",
    }),
    backToRankList(name) {
      console.log(name);
      const activeName = this.$storage.getItem("activeName");
      if (
        name === activeName ||
        name === "达人榜单" ||
        name === "追加KOL" ||
        name === "创建活动"
      ) {
        if (this.isAddClass) {
          this.setIsAddClass(false);
          this.setDialogClose(true);
        } else {
          return "";
        }
      }
    },
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val);
        if (
          val.name === "RankingListV2" ||
          val.path.includes("/collectDetail") ||
          val.path.includes("/additionalKol") ||
          val.path.includes("/createCampaign")
        ) {
          this.AddClass = true;
        } else {
          this.AddClass = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1400px) {
  .breadWrap {
    width: 100% !important;
  }
}

.breadWrap {
  //width: 1400px;
  width:100%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  .breadIcon {
    font-size: 20px;
    width: 20px;
    color:#fff;
  }

  .elBreadCrumb {
    flex: 1;
    margin-left: 10px;
  }

  .backToRanklist {
    cursor: pointer;

    &:hover {
      color: #409eff;
    }
  }
}
</style>
<style lang="scss">
.elBreadCrumb {
  flex: 1;
  .el-breadcrumb__item .el-breadcrumb__inner,.el-breadcrumb__separator{
    color:#fff!important;
  }
}
</style>
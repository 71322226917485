import request from "../utils/axios";

export function sendCode(params) {
  return request.post("/api/auth/wechat", params);
}

export function refreshToken(params) {
  return request.get(
    "https://api.weixin.qq.com/sns/oauth2/refresh_token",
    params
  );
}

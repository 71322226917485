import axios from "axios";
import storage from "@/utils/storage";
import { ElMessage } from "element-plus";
import router from "../router/index";
import qs from "qs";
import store from "../store";

let loadingRequestCount = 0;

const baseURL = process.env.VUE_APP_API_HOST;
const service = axios.create({
  baseURL,
});

// 添加全局loading，用来控制组件loading/可使用
//显示loading
const showLoading = () => {
  loadingRequestCount++;
  store.commit("SET_AXIOSLOADING", true);
};

//隐藏loading
const hideLoading = () => {
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    store.commit("SET_AXIOSLOADING", false);
  }
};
// generateReqKey 用于根据当前请求信息，生成请求 key
// const generateReqKey = (config) => {
//   const { method, url, params, data } = config;
//   return [method, url, qs.stringify(params), qs.stringify(data)].join("&");
// };
// addPendingRequest 用于把当前请求信息添加到pendingRequest对象中
// const pendingRequest = new Map();
// const addPendingRequest = (config) => {
//   const requestKey = generateReqKey(config);
//   config.cancelToken =
//     config.cancelToken ||
//     new axios.CancelToken((cancel) => {
//       if (!pendingRequest.has(requestKey)) {
//         pendingRequest.set(requestKey, cancel);
//       }
//     });
// };
// removePendingRequest 检查是否存在重复请求若存在，取消请求
// const removePendingRequest = (config) => {
//   const requestKey = generateReqKey(config);
//   if (pendingRequest.has(requestKey)) {
//     const cancelToken = pendingRequest.get(requestKey);
//     cancelToken(requestKey);
//     pendingRequest.delete(requestKey);
//   }
// };

// 请求拦截器
service.interceptors.request.use((config) => {
  // removePendingRequest(config); // 检查是否存在重复请求，若存在则取消已经发送的请求
  // addPendingRequest(config); // 把当前请求信息添加到pendingRequest对象中
  // todo: jwt
  showLoading();
  if (storage.getItem("token")) {
    config.headers.token = storage.getItem("token");
  }
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // removePendingRequest(res.config);
    hideLoading();
    const { msg } = res.data;
    if (res.data) {
      return res.data;
    } else {
      ElMessage.error(msg);
      setTimeout(() => {
        router.push("/homeIndex");
      }, 2000);
      return Promise.reject(msg);
    }
  },
  (error) => {
    // removePendingRequest(error.config || {});
    if (axios.isCancel(error)) {
      console.log("已取消的重复请求：" + error.message);
    }
    hideLoading();
    //响应错误
    if (error.response?.config && error.response.config.responseType)
      return Promise.reject(error);
    if (error.response && error.response.status) {
      const status = error.response.status;
      let msg;
      switch (status) {
        case 400:
          msg = "请求错误";
          break;
        case 404:
          msg = "请求地址出错";
          break;
        case 401:
          msg = "401";
          break;
        case 408:
          msg = "请求超时";
          break;
        case 500:
          msg = "服务器内部错误!";
          break;
        case 501:
          msg = "服务未实现!";
          break;
        case 502:
          msg = "网关错误!";
          break;
        case 503:
          msg = "服务不可用!";
          break;
        case 504:
          msg = "网关超时!";
          break;
        case 505:
          msg = "HTTP版本不受支持";
          break;
        case 3012:
          msg = "超出后侧配额，请联系管理员";
          break;
        default:
          msg = "请求失败";
      }
      if (msg !== "401") {
        ElMessage.error(msg);
      }
      setTimeout(() => {
        router.push("/homeIndex");
      }, 2000);
      return Promise.reject(error);
    }
  }
);

function objToStr(obj) {
  let arr = [];
  for (let key in obj) {
    arr.push(key + "=" + obj[key]);
  }
  return arr.join("&");
}

function formatOptions(options) {
  options.method = options.method || "get";
  if (options.data) {
    if (options.method.toLowerCase() === "post") {
      if (options.url !== "/crowd/createCrowd") {
        options.data = qs.stringify(options.data);
      } else {
        options.data = objToStr(options.data);
      }
    } else {
      if (options.url === "/api/user/collection/crowd1") {
        options.params = options.data;
        options.paramsSerializer = (params) => objToStr(params);
      } else {
        options.params = options.data;
        options.paramsSerializer = (params) =>
          qs.stringify(params, { indices: false });
      }
    }
  }
  return options;
}

function request(options) {
  options = formatOptions(options);
  return service(options);
}

// 另一种封装
["get", "post", "put", "delete", "patch"].forEach((item) => {
  request[item] = (url, data, options) => {
    return request({
      method: item,
      url,
      data,
      ...options,
    });
  };
});

export default request;

export function applicationJson(options) {
  return service(options);
}

// applicationJson封装
["post", "put"].forEach((item) => {
  applicationJson[item] = (url, data, options) => {
    return applicationJson({
      method: item,
      url,
      data,
      ...options,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  };
});

export function uploadFile(url, file) {
  let data = new FormData();
  data.append("file", file);
  const options = {
    method: "post",
    url,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return service(options);
}

export function downloadFile(
  url,
  params,
  fileName,
  options = {
    method: "get",
    data: "",
  }
) {
  return new Promise((resolve, reject) => {
    options.data = params;
    if (
      url !== "/tool/sov/article" &&
      url !== "/tool/sov/persona" &&
      url !== "/tool/sov/kol"
    ) {
      options = formatOptions(options);
    }
    service({
      url,
      responseType: "blob",
      ...options,
    })
      // res经过响应拦截器处理
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        resolve(true);
      })
      .catch((e) => reject(e));
  });
}

export function downloadLocalFile(url, fileName) {
  service({
    url,
    method: "get",
    responseType: "blob",
    baseURL: "",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  });
}

export function formatRes(res) {
  let temp = [];
  for (let k in res.interest) {
    temp.push(res.interest[k]);
  }
  temp.sort((a, b) => {
    return b.tgi - a.tgi;
  });
  let maxObj = {
    gender: [],
    age: {
      series1: [],
      series2: [],
      xaxias: [],
    },
    income: {
      series1: [],
      series2: [],
      xaxias: [],
    },
    city: {
      series1: [],
      series2: [],
      xaxias: [],
    },
    topDiv: {
      series1: [],
      series2: [],
      xaxias: [],
    },
    ta:{
      series1: [],
      xaxias: [],
    },
    province: [],
  };
  for (let k in res.gender) {
    maxObj.gender.push(res.gender[k]);
  }
  maxObj.gender.forEach((v) => {
    v.value = v.pct * 100;
  });
  for (let k in res.age) {
    maxObj.age.xaxias.unshift(res.age[k].name);
    maxObj.age.series1.unshift(res.age[k].pct);
    maxObj.age.series2.unshift(res.age[k].tgi);
  }
  for (let k in res.income) {
    maxObj.income.xaxias.unshift(res.income[k].name);
    maxObj.income.series1.unshift(res.income[k].pct);
    maxObj.income.series2.unshift(res.income[k].tgi);
  }
  temp.slice(0, 7).forEach((v) => {
    maxObj.topDiv.xaxias.push(v.name);
    maxObj.topDiv.series1.push(v.pct);
    maxObj.topDiv.series2.push(v.tgi);
  });
  for (let k in res.cityTier) {
    maxObj.city.xaxias.unshift(res.cityTier[k].name);
    maxObj.city.series1.unshift((res.cityTier[k].pct * 100).toFixed(2));
    maxObj.city.series2.unshift(res.cityTier[k].tgi.toFixed(2));
  }
  for (let k in res.province) {
    maxObj.province.unshift({
      name: res.province[k].name,
      series1: (res.province[k].pct * 100).toFixed(2),
      series2: res.province[k].tgi.toFixed(2),
    });
  }
  for (let k in res.ta) {
    maxObj.ta.xaxias.unshift(res.ta[k].name);
    maxObj.ta.series1.unshift((res.ta[k].pcg * 100).toFixed(2));
  }
  return maxObj;
}

export function setLogoUrl(data) {
  data.forEach((v) => {
    v.logo = process.env.VUE_APP_API_HOST + v.logo;
  });
  return data;
}

export function replaceNbsp(data) {
  data.forEach((v) => {
    v.isShowMoreArticle = false;
    v.articleInfoList.forEach((k) => {
      k.title = k.title.replace(/&nbsp;/g, " ");
    });
  });
  return data;
}

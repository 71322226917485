import { createRouter, createWebHistory } from "vue-router";
import storage from "@/utils/storage";
import { atob } from "@/utils/tools";
import store from "@/store";

const routes = [
  {
    name: "Homepage",
    path: "/homepage",
    component: () => import("@/views/Homepage"),
  },
  // {
  //   name: "Home",
  //   path: "/homeIndex",
  //   component: () => import("@/views/HomeIndex"),
  // },
  {
    name: "Home",
    path: "/homeIndex",
    component: () => import("@/views/Login/index"),
  },
  {
    name: "Redirect",
    path: "/redirect",
    component: () => import("@/views/Redirect"),
  },
  {
    name: "UpdatePage",
    path: "/updatePage",
    component: () => import("@/views/UpdatePage"),
  },
  {
    name: "RankingListV2",
    path: "/rankingListV2",
    component: () => import("@/views/RankListV2"),
    meta: {
      keepAlive: true, //设置页面是否需要使用缓存
    },
  },
  {
    name: "AmateurRankingList",
    path: "/amateurRankingList",
    component: () => import("@/views/AmateurRankings"),
    meta: {
      keepAlive: false, //设置页面是否需要使用缓存
    },
  },
  {
    name: "Admin",
    path: "/admin",
    component: () => import("@/views/Admin"),
  },
  {
    name: "Manage",
    path: "/manage",
    component: () => import("@/views/Manage"),
  },
  // 活动洞察
  {
    name: "CampaignInsight",
    path: "/campaignInsight",
    component: () => import("@/views/CampaignInsight"),
  },
  // 多活动对比
  {
    name: "InsightCompare",
    path: "/insightCompare",
    component: () => import("@/views/CampaignInsight/insightCompare.vue"),
    props: ({ query: { selection } }) => ({
      id: selection.map((id) => atob(id)).toString(),
    }),
  },
  // 活动日历
  {
    name: "InsightCalendar",
    path: "/insightCalendar",
    component: () => import("@/views/CampaignInsight/insightCalendar.vue"),
    props: ({ query: { selection } }) => {
      const id =
        typeof selection === "string"
          ? atob(selection)
          : selection.map((id) => atob(id)).toString();
      return { id };
    },
  },
  // 活动详情
  {
    name: "InsightDetail",
    path: "/insightDetail",
    component: () => import("@/views/CampaignInsight/insightDetail.vue"),
    props: ({ query: { id } }) => ({ id: atob(id) * 1 }),
  },
  // 报价管理
  {
    name: "Quote",
    path: "/quote",
    component: () => import("@/views/Quote"),
  },
  // 报价详情
  {
    name: "QuoteDetail",
    path: "/quoteDetail",
    component: () => import("@/views/QuoteDetail"),
    props: ({ query: { campaignId, agencyId, campaignStatus } }) => ({
      campaignId: atob(campaignId) * 1,
      agencyId: atob(agencyId) * 1,
      campaignStatus: atob(campaignStatus),
    }),
  },
  //供应商管理
  {
    name: "Agency",
    path: "/agency",
    component: () => import("@/views/Agency"),
  },
  // 仪表盘
  {
    name: "DashBoard",
    path: "/dashboard",
    component: () => import("@/views/Dashboard"),
  },
  {
    name: "MKolIdConversion",
    path: "/mkolidConversion",
    component: () => import("@/views/MKolIdConversion"),
  },
  {
    name: "DownLoadCrowd",
    path: "/loadCrowd",
    component: () => import("@/views/DownloadCrowd"),
  },
  // 常用达人
  {
    name: "KolCollection",
    path: "/kolCollection",
    component: () => import("@/views/KolCollection"),
  },
  {
    name: "CollectDetail",
    path: "/collectDetail",
    component: () => import("@/views/CollectDetail"),
    props: ({ query: { collectionId } }) => ({
      collectionId: atob(collectionId) * 1,
    }),
  },
  {
    name: "CampaignManage",
    path: "/campaign",
    component: () => import("@/views/CampaignManage"),
  },
  {
    name: "CampaignInfo",
    path: "/campaignInfo",
    component: () => import("@/views/CampaignInfo"),
    props: ({ query: { id } }) => ({
      id: atob(id) * 1,
    }),
  },
  {
    name: "CampaignReport",
    path: "/campaignReport",
    component: () => import("@/views/CampaignReport"),
    props: ({ query: { id } }) => ({
      id: atob(id) * 1,
    }),
  },
  {
    name: "CampaignCompare",
    path: "/campaignCompare",
    component: () => import("@/views/CampaignCompare"),
    props: ({ query: { campaigns } }) => ({
      campaignArr: atob(campaigns).split(","),
    }),
  },
  {
    name: "CreateCampaign",
    path: "/createCampaign",
    component: () => import("@/views/CreateCampaign"),
  },
  // 追加kol
  {
    name: "AdditionalKol",
    path: "/additionalKol",
    component: () => import("@/views/AdditionalKol"),
    props: ({ query: { id } }) => ({ id: atob(id) * 1 }),
  },
  // 添加kol
  {
    name: "AddKol",
    path: "/addKol",
    component: () => import("@/views/AddKol"),
    props: ({ query: { collectionId } }) => ({
      collectionId: atob(collectionId) * 1,
    }),
  },
  // 品牌榜单
  {
    name: "sovBrandList",
    path: "/brandRankingList",
    component: () => import("@/views/sov/sovBrand"),
  },
  // 品牌榜单-品牌详情
  {
    name: "sovBrand",
    path: "/sovBrand",
    component: () => import("@/views/sov/sovBrand/brand"),
    props: ({ query: { brand, brandId, duration } }) => ({
      brand: decodeURI(brand),
      id: atob(brandId),
      duration,
    }),
  },
  // 品牌榜单-多品牌对比
  {
    name: "sovBrandCompare",
    path: "/sovBrandCompare",
    component: () => import("@/views/sov/sovBrand/brandCompare"),
    props: ({ query: { brands, brandNameList, duration } }) => ({
      brands: atob(brands),
      brandNameList: JSON.parse(decodeURI(brandNameList)),
      duration,
    }),
  },
  // 产品榜单
  {
    name: "sovProductList",
    path: "/productRankingList",
    component: () => import("@/views/sov/sovProduct"),
  },
  // 产品榜单-产品详情
  {
    name: "sovProduct",
    path: "/sovProduct",
    component: () => import("@/views/sov/sovProduct/sovProduct"),
    props: ({ query: { product, productId, duration } }) => ({
      product: decodeURI(product),
      id: atob(productId),
      duration,
    }),
  },
  // 产品榜单-多产品对比
  {
    name: "sovProductCompare",
    path: "/sovProductCompare",
    component: () => import("@/views/sov/sovProduct/sovProductCompare"),
    props: ({ query: { products, productNameList, duration } }) => ({
      products: atob(products),
      productNameList: JSON.parse(decodeURI(productNameList)),
      duration,
    }),
  },
  // 品类榜单
  {
    name: "sovCategoryList",
    path: "/categoryRankingList",
    component: () => import("@/views/sov/sovCategory"),
  },
  // 品类榜单-品类详情
  {
    name: "sovCategory",
    path: "/sovCategory",
    component: () => import("@/views/sov/sovCategory/sovCate"),
    props: ({ query: { category, categoryId, duration } }) => ({
      category: decodeURI(category),
      id: atob(categoryId),
      duration,
    }),
  },
  // 品类榜单-多品类对比
  {
    name: "sovCategoryCompare",
    path: "/sovCategoryCompare",
    component: () => import("@/views/sov/sovCategory/sovCategoryCompare"),
    props: ({ query: { categories, categoryNameList, duration } }) => ({
      categories: atob(categories),
      categoryNameList: JSON.parse(decodeURI(categoryNameList)),
      duration,
    }),
  },
  // 美妆-dashboard
  {
    name: "CosmeticDashboard",
    path: "/cosmeticDashboard",
    component: () => import("@/views/Cosmetic"),
  },
  // 美妆-趋势追踪
  {
    name: "TrendTracking",
    path: "/trendTracking",
    component: () => import("@/views/Tendency"),
  },
  // 美妆-趋势追踪
  {
    name: "TrendTrackingDetails",
    path: "/trendTrackingDetails",
    component: () => import("@/views/Tendency/trendTrackingDetails"),
  },
  // 美妆-产品库
  {
    name: "ProductClaim",
    path: "/productClaim",
    component: () => import("@/views/ProductRepo/productClaim"),
  },
  // 美妆-产品库
  {
    name: "ProductRepo",
    path: "/productRepo",
    component: () => import("@/views/ProductRepo"),
  },
  //美妆-CHANEL Dashboard
  {
    name: "ChanelDashboard",
    path: "/chanelDashboard",
    component: () => import("@/views/ChanelDashboard"),
  },
  // 日志收集
  {
    name: "LogAdmin",
    path: "/logAdmin",
    component: () => import("@/views/LogAdmin"),
  },
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    redirect: "/homeIndex",
  },
  // 品牌/产品 数据下载工具
  {
    name: "DownloadDataTool",
    path: "/brandDataDownload",
    component: () => import("@/views/tools/brandProductDataDownload"),
  },
  // 后测数据筛选下载 -> 工具
  {
    name: "HcDataDownload",
    path: "/hcDataDownload",
    component: () => import("@/views/tools/hcDataDownload"),
  },

  {
    name: "HcCreatingTasks",
    path: "/hcCreatingTasks",
    component: () => import("@/views/tools/components/creatingTasks.vue"),
  },

  // analysisReport
  {
    name: "AnalysisReport",
    path: "/analysisReport",
    component: () => import("@/views/sov/listeningReport"),
  },
  {
    name: "campaignTrace",
    path: "/campaignTrace",
    component: () => import("@/views/CampaignTrace/campaignManage.vue"),
  },
  {
    name: "CreateCampaignTrace",
    path: "/createCampaignTrace",
    component: () => import("@/views/CampaignTrace/createCampainTrace.vue"),
  },
  {
    name: "CampaignCompareTrace",
    path: "/campaignCompareTrace",
    component: () => import("@/views/CampaignTrace/campaignCompare.vue"),
    props: ({ query: { campaigns } }) => ({
      campaignArr: atob(campaigns).split(","),
    }),
  },
  {
    name: "CampaignInfoTrace",
    path: "/campaignInfoTrace",
    component: () => import("@/views/CampaignTrace/campaignInfo.vue"),
    props: ({ query: { id } }) => ({
      id: atob(id) * 1,
    }),
  },
  {
    name: "CampaignReportTrace",
    path: "/campaignReportTrace",
    component: () => import("@/views/CampaignTrace/campaignReport.vue"),
    props: ({ query: { id } }) => ({
      id: atob(id) * 1,
    }),
  },
  {
    name: "NegativeReport",
    path: "/negativeReport",
    component: () => import("@/views/NegativeReport"),
    props: ({ query: { id } }) => ({
      id: atob(id) * 1,
    }),
  },
  // 仪表盘
  {
    name: "dashboardTrace",
    path: "/dashboardTrace",
    component: () => import("@/views/CampaignTrace/dashboardTrace.vue"),
  },
  // 追加kol
  {
    name: "AdditionalKolTrace",
    path: "/AdditionalKolTrace",
    component: () => import("@/views/CampaignTrace/AdditionalKol"),
    props: ({ query: { id } }) => ({ id: atob(id) * 1 }),
  },
];

// 权限
// 1  admin 管理员  超级管理员
// 2  kolmem  kol成员 普通帐号，基本功能权限
// 3  trial-admin 产品试用管理员  拥有开通试用帐号的管理员
// 4  trial-kolmem  产品试用账号  产品试用账号，可查看数据有限制
// 5  trace-manager 投放需求审计员  投放需求审计员
// 6  kolmem2 kol成员 普通帐号，增加投放监测功能权限

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

// 全局路由守卫
router.beforeEach(async (to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  if (to.path === "/homeIndex") {
    store.commit("SET_TOKEN", "");
    storage.clearAll();
    next();
    return false;
  }
  // 本地读取menulist
  let menuRouteList = storage.getItem("menuList") || [];
  if (menuRouteList.includes("/shareOfVoice")) {
    menuRouteList.push("/taskDetails");
  }
  const token = storage.getItem("token");
  console.log(token, "token");
  // session有token，vuex没有token，对应刷新情况
  if (
    (token && !store.getters.token) ||
    (from.name === "Manage" && to.name === "RankingListV2") ||
    token
  ) {
    store.commit("SET_TOKEN", token);
    // 存取站点信息
    const siteArr = storage.getItem("sites");
    const siteValue = storage.getItem("siteValue");
    store.commit("SET_SITES", siteArr);
    store.commit("SET_SITEVALUE", siteValue);
    if (from.path !== "/homeIndex") {
      await store.dispatch("logInAction", false);
    }

    if (
      to.path === "/shareOfVoice" ||
      to.path.indexOf("/taskDetails") !== -1 ||
      to.path.indexOf("/kolCollection") !== -1 ||
      to.path === "/rankingListV2"
    ) {
      let flag = menuRouteList.some((v) => {
        return to.path.indexOf(v) !== -1;
      });
      if (flag) {
        next();
      } else {
        next("/homepage");
      }
    } else {
      next();
    }
  } else if (!token && to.path !== "/redirect") {
    next("/homeIndex");
  } else {
    next();
  }
});

export default router;

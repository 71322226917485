import {amateurSiteValue} from "./getters";
const state = {
  token: "",
  refreshToken: "",
  updateText: "",
  crowdList: [],
  targetCrowd: {},
  month: "",
  /*
   * 1：管理员
   * 2：审计员
   * 3：操作员
   * 4：供应商
   * */
  role: "",
  menuList: [],
  amateurSiteValue:[],
  // kol详情中是否展示合作信息
  brandCoop: 0,
  // 是否显示登录框
  isShowLoginDialog: false,
  sites: [],
  siteValue: "wechat",
  // 全局loading
  axiosLoading: false,
  // 榜单分类信息
  classifyData: {},

  // kol详情设置自己的榜单点击返回效果
  isAddClass: false,
  dialogClose: false,

  // 控制榜单的全部打开/收起
  isExpand: false,
  // 榜单排序名称，用于展示
  listSortLabel: "近4周触达UV降序",
  // 榜单版本 0：榜单1.0；1：榜单2.0
  listVersion: 1,

  // dashboard 中的人群
  dashBoardCrowd: {},
  // 榜单列是否高亮标识
  isHighLight: true,
  // 榜单展开内容的搜索
  orderRule: "",

  // 常用达人页面中的一些数据
  colTargetCrowd: {},
  colSites: [],
  colSiteValue: "wechat",
  colOrderRule: "",

  // 以下不知是否有用
  // 是否缓存该页面
  cacheViews: [],
  done: false,
  colDone: false,
  isCatchPage: true,
  initDataFlag: false,
  colOrderFiled: "",
  createCampaignOrderFiled: "",
  campaignInfoOrderFiled: "",
  // 记录活动 中kol数量
  campaignKolNum: 0,
  // 保存sov页码 brand
  sovPageSave: 1,
  sovCatePageSave: 1,
  sovProductPageSave: 1,

  detailsToRankList: "",
  // 记录常用大人平台
  collectionSite: "",
  // 存储base64截图
  base64List: [],
  // 单品牌人群
  brandSeg: "32_1",
  // 多品牌人群
  brandsSeg: "32_1",
  // 单产品人群
  productSeg: "32_1",
  // 多产品人群
  productsSeg: "32_1",
};

export default state;

import { createStore } from "vuex";

import * as actions from "./actions";
import * as getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import kolDetails from "./modules/kolDetails";
import { createLogger } from "vuex";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state,
  mutations,
  getters,
  actions,
  modules: {
    kolDetails,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

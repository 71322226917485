import request from "@/utils/axios";

export function getTrend(params) {
  return request.post("/kol/getKolHistory", params);
}

export function getDetailsTrend(params) {
  return request.get("/kol/history", params);
}

export function getArticleList(params) {
  return request.get("/kol/hotpost", params);
}

export function getPersona(params) {
  return request.get("/kol/persona", params);
}

export function getDetailsCoop(mKolid) {
  return request.get("/kol/detail/coop", { mKolid });
}

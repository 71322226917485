<template>
  <img
    v-if="value.includes('weixin')"
    :src="value"
    :style="{ width: options.width + 'px' }"
  />
  <vue-qrcode v-else :value="value" :options="options" />
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  name: "qrcode",
  props: {
    value: {
      type: String,
      default: "weixin",
    },
    options: {
      type: Object,
      default() {
        return { width: 110 };
      },
    },
  },
  components: {
    VueQrcode,
  },
};
</script>

<style scoped></style>

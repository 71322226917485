import request, { applicationJson, downloadFile } from "@/utils/axios";

export function initFilter() {
  return request.get("/kol/initFilter");
}

export function initData(params) {
  return request.post("/kol/initData", params);
}

export function selectLoadMore(params) {
  return request.post("/kol/kols", params);
}

export function selectSearch(params) {
  return request.post("/kol/kolsBykey", params);
}

export function selectGetData(params) {
  return applicationJson.post("/kol/dataByKols", params);
}

export function userProfileInitData(params) {
  return request.post("/kol/userProfile", params);
}

export function touchAnalysisInitData(params) {
  return request.post("/kol/taData", params);
}

export function getPopoverData(params) {
  return request.get("/kol/detail", params);
}

export function getKolDetails(params) {
  return request.get("/kol/detail", params);
}

export function getCompareData(params) {
  return request.post("/kol/distribute", params);
}

export function getFavorList(params) {
  return request.post("/kol/getFavorList", params);
}

export function handleFavorTag(params) {
  return request.post("/kol/favorTag", params);
}

export function handleFavor(params) {
  return request.post("/kol/favor", params);
}

export function checkCrowdName(params) {
  return request.post("/crowd/isExist", params);
}

// 提交申请
export function createCrowd(params) {
  return request.post("/trace/requireKolTrace", params);
}

/* new api
* String seg;//人群
String site;//平台
String type;//month or week
String order;
Integer sort;
Integer page;
String kolNames;//搜索
List<String> tag1;//标签
List<String> favor;//收藏
List<String> kolIds;//选择
Map<String, Object> filter;//其他筛选
* */
export function getKolList(params) {
  return applicationJson.post("/kol/rankinglist", params);
}

export function getDuration(params) {
  return request.get("/kol/duration", params);
}

export function getBenchmark(params) {
  return request.get("/kol/benchmark", params);
}

export function personalAnalysis(params) {
  return request.get("/kol/persona/analysis", params);
}

export function getKolListV2(params) {
  return applicationJson.post("/kol/v2", params);
}

// 2.0 触达分析
export function kolTouchAnalysis(params) {
  return request.get("/kol/touch/analysis", params);
}

// 获取所有标签  /kol/label/
export function getKolLabel(params) {
  return request.get("/kol/label", params);
}

// getKolLabel, delKolLabel, addNewKolLabel
// 删除标签
export function delKolLabel(params) {
  return request.delete("/kol/label", params);
}

// 新增标签
export function addNewKolLabel(params) {
  return applicationJson.post("/kol/label", params);
}

// 下载各个tier中位数  /kol/median
export function downloadKolMedian(params, fileName) {
  return downloadFile("/kol/median", params, fileName);
}

//获取单个kol的gptImage数据
export function detailGPT(params) {
  return request.get("/kol/detail/gpt/image", params);
}
//素人榜单获取kol相关数据
export function getInfo(){
  return request.get("/kol/average/list/info")
}

export function getkolAverageDataList(params){
  return applicationJson.post("/kol/kolAverageDataList",params)
}
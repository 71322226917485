export const appid = () => {
  console.log(process.env.VUE_APP_NODE_ENV);
  if (process.env.VUE_APP_NODE_ENV === "production") {
    return "wxcc74683f50f8566a";
  } else {
    return "wxdafa5c1906e032ed";
  }
};
export const secret = () => {
  if (process.env.VUE_APP_NODE_ENV === "production") {
    return "768a94b40f26e29b419ecf122753120f";
  } else {
    return "2651ff4aa4652ac7fa623875556d963d";
  }
};
export const redirectUrl = () => {
  if (process.env.VUE_APP_NODE_ENV === "production") {
    return "http://omnivoice.meritco-group.com/redirect";
  } else if (process.env.VUE_APP_NODE_ENV === "development") {
    return "http://internal.meritco-group.com/qr-redirect.html";
  } else {
    return "http://internal.meritco-group.com/redirect";
  }
};
export const token = (state) => state.token;
//refreshToken可能是token也可能是{ message }
export const refreshToken = (state) => state.refreshToken;
export const updateText = (state) => state.updateText;
export const crowdList = (state) => state.crowdList;
export const targetCrowd = (state) => state.targetCrowd;
export const categorySeg = (state) => state.targetCrowd.key;
export const month = (state) => state.month;
export const role = (state) => state.role;
export const menuList = (state) => state.menuList;
export const sites = (state) => state.sites;

export const amateurSiteValue = (state) => state.amateurSiteValue;
export const siteValue = (state) => state.siteValue;
export const cascaderSite = (state) => state.cascaderSite;

// 榜单列表的展开/收起
export const isExpand = (state) => state.isExpand;
// table的高量
export const isHighLight = (state) => state.isHighLight;
// 排序列
export const orderRule = (state) => state.orderRule;
export const listSortLabel = (state) => state.listSortLabel;
export const listVersion = (state) => state.listVersion;
// 收藏的数据
export const colTargetCrowd = (state) => state.colTargetCrowd;
export const dashBoardCrowd = (state) => state.dashBoardCrowd;
export const colCategorySeg = (state) => state.colTargetCrowd.key;
export const dashBoardCrowdSeg = (state) => state.dashBoardCrowd.key;

export const colOrderRule = (state) => state.colOrderRule;
export const colSites = (state) => state.colSites;
export const colSiteValue = (state) => state.colSiteValue;

export const categoryId = (state) => state.targetCrowd.category;
export const keepAlive = (state) => state.keepAlive;

export const isAddClass = (state) => state.isAddClass;
export const axiosLoading = (state) => state.axiosLoading;
export const classifyData = (state) => state.classifyData;

<template>
  <svg class="icon" aria-hidden="true">
    <use :xlink:href="`#${className}`" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    className: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.2em;
  fill: currentColor;
  overflow: hidden;
}
</style>

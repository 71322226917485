import * as types from "./mutation-types";
import storage from "@/utils/storage";

const mutation = {
  [types.SET_TOKEN](state, token) {
    storage.setItem("token", token);
    state.token = token;
  },
  [types.SET_REFRESH_TOKEN](state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  [types.SET_UPDATETEXT](state, text) {
    state.updateText = text;
  },
  [types.SET_FILTERINFO](state, data) {
    state.filterInfo = data;
  },
  [types.SET_CROWDLIST](state, list) {
    storage.setItem("corwdListStorage", list);
    state.crowdList = list;
  },
  [types.SET_ISDISABLED](state, isDisabled) {
    state.isDisabled = isDisabled;
  },
  [types.SET_AMATEURSITES](state, sites) {
    storage.setItem("amateurSiteValue", sites);
    state.amateurSiteValue = sites;
  },
  [types.SET_TARGETCROWD](state, crowd) {
    state.targetCrowd = crowd;
  },
  [types.SET_MONTH](state, month) {
    state.month = month;
  },
  [types.SET_ROLE](state, role) {
    state.role = role;
  },
  [types.SET_BRANDCOOP](state, brandCoop) {
    state.brandCoop = brandCoop;
  },
  [types.SET_MENULIST](state, list) {
    state.menuList = list;
  },
  [types.SET_SITES](state, sites) {
    storage.setItem("sites", sites);
    state.sites = sites;
  },
  [types.SET_CASCADERSITE](state, cascaderSite) {
    state.cascaderSite = cascaderSite;
  },
  [types.SET_SITEVALUE](state, site) {
    storage.setItem("siteValue", site);
    state.siteValue = site;
  },
  [types.SET_ISEXPAND](state, isExpand) {
    state.isExpand = isExpand;
  },
  [types.SET_ORDERRULE](state, orderRule) {
    state.orderRule = orderRule;
  },
  [types.SET_LISTSORTLABEL](state, label) {
    state.listSortLabel = label;
  },
  [types.SET_ISHIGHLIGHT](state, isHighLight) {
    state.isHighLight = isHighLight;
  },
  [types.SET_COLTARGETCROWD](state, crowd) {
    state.colTargetCrowd = crowd;
  },
  // dashBoardCrowd
  [types.SET_DASHBOARDCROWD](state, crowd) {
    state.dashBoardCrowd = crowd;
  },
  [types.SET_COLORDERRULE](state, colOrderRule) {
    state.colOrderRule = colOrderRule;
  },
  [types.SET_COLSITES](state, sites) {
    storage.setItem("colSites", sites);
    state.colSites = sites;
  },
  [types.SET_COLSITEVALUE](state, site) {
    storage.setItem("colSiteValue", site);
    state.colSiteValue = site;
  },
  [types.SET_LISTVERSION](state, version) {
    state.listVersion = version;
  },

  [types.SET_KEEP_ALIVE](state, cacheViews) {
    state.cacheViews = cacheViews;
  },
  [types.SET_COLDONE](state, colDone) {
    state.colDone = colDone;
  },
  [types.SET_INITDATAFLAG](state, initDataFlag) {
    state.initDataFlag = initDataFlag;
  },
  [types.SET_ISCATCHPAGE](state, isCatchPage) {
    state.isCatchPage = isCatchPage;
  },

  [types.SET_COLORDERFILED](state, colOrderFiled) {
    state.colOrderFiled = colOrderFiled;
  },
  [types.SET_CREATECAMPAIGNORDERFILED](state, createCampaignOrderFiled) {
    state.createCampaignOrderFiled = createCampaignOrderFiled;
  },
  [types.SET_CAMPAIGNINFOORDERFILED](state, campaignInfoOrderFiled) {
    state.campaignInfoOrderFiled = campaignInfoOrderFiled;
  },
  [types.SET_CAMPAIGNKOLNUM](state, campaignKolNum) {
    state.campaignKolNum = campaignKolNum;
  },
  [types.SET_SOVPAGESAVE](state, sovPageSave) {
    state.sovPageSave = sovPageSave;
  },
  [types.SET_SOVCATEPAGESAVE](state, sovCatePageSave) {
    state.sovCatePageSave = sovCatePageSave;
  },
  [types.SET_PRODUCTPAGESAVE](state, sovProductPageSave) {
    state.sovProductPageSave = sovProductPageSave;
  },
  [types.SET_ISSHOWLOGINDIALOG](state, isShowLoginDialog) {
    state.isShowLoginDialog = isShowLoginDialog;
  },
  [types.SET_DETAILSTORANKLIST](state, detailsToRankList) {
    state.detailsToRankList = detailsToRankList;
  },
  // collectionSite SET_COLLECTIONSITE
  [types.SET_COLLECTIONSITE](state, collectionSite) {
    state.collectionSite = collectionSite;
  },
  // isAddClass SET_ISADDCLASS
  [types.SET_ISADDCLASS](state, isAddClass) {
    state.isAddClass = isAddClass;
  },
  [types.SET_DIALOGCLOSE](state, dialogClose) {
    state.dialogClose = dialogClose;
  },
  [types.SET_AXIOSLOADING](state, loading) {
    state.axiosLoading = loading;
  },
  [types.SET_CLASSIFYDATA](state, data) {
    state.classifyData = data;
  },
  [types.SET_BASE64LIST](state, data) {
    state.base64List = data;
  },
  // //   SET_BRANDSEG SET_BRANDSSEG SET_PRODUCTSEG SET_PRODUCTSSEG
  [types.SET_BRANDSEG](state, data) {
    state.brandSeg = data;
  },
  [types.SET_BRANDSSEG](state, data) {
    state.brandsSeg = data;
  },
  [types.SET_PRODUCTSEG](state, data) {
    state.productSeg = data;
  },
  [types.SET_PRODUCTSSEG](state, data) {
    state.productsSeg = data;
  },
};

export default mutation;

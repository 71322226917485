import request from "@/utils/axios";

export function getToken(params) {
  // return get('http://localhost:3000/static/data/token.json', params)
  return request.post("/user/token", params);
}

export function login() {
  // return get('http://localhost:3000/static/data/userInfo.json', params)
  return request.get("/user/info");
}

export function getMonth() {
  return request.get("/kol/month");
}

const nameSpace = process.env.VUE_APP_NAMESPACE;
// 自动stringify/parse
export default {
  getStorage() {
    return JSON.parse(window.localStorage.getItem(nameSpace)) || {};
  },
  setItem(key, value) {
    const storage = this.getStorage();
    storage[key] = value;
    window.localStorage.setItem(nameSpace, JSON.stringify(storage));
  },
  getItem(key) {
    const storage = this.getStorage();
    return storage[key];
  },
  clearItem(key) {
    const storage = this.getStorage();
    delete storage[key];
    window.localStorage.setItem(nameSpace, JSON.stringify(storage));
  },
  clearAll() {
    window.localStorage.clear();
  },
};

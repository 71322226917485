import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import lazyPlugin from "vue3-lazy";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "./assets/iconfont/iconfont";
import "./style/index.scss";
import "./style/reset.css";
import "./style/reset_element.scss";
import "./utils/wxLogin";
import request from "./utils/axios";
import storage from "./utils/storage";
import {
  sliceDecimal,
  platformToZn,
  urlToPlatform,
  formatNull,
  formatMoneyNum,
  atob,
  btoa,
  sha256,
  postTypeToZn,
} from "@/utils/tools";
import SvgIcon from "@/components/common/svgicon.vue";
import countTo from "vue3-count-to";
import qrCode from "@/components/common/qrcode";

const req = require.context("@/assets/svgicons/svgs", false, /\.svg$/);
const requireAll = (requireContext) => {
  requireContext.keys().map(requireContext);
};
requireAll(req);

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Antd);
app.use(ElementPlus, { locale: zhCn });
app.use(lazyPlugin, {
  loading: require("@/assets/image_loading.gif"),
  // error: require('@/assets/image_loading.gif')
});
app.use(countTo);

// 全局挂载属性
app.config.globalProperties.$request = request;
app.config.globalProperties.$storage = storage;
app.config.globalProperties.sliceDecimal = sliceDecimal;
app.config.globalProperties.platformToZn = platformToZn;
app.config.globalProperties.urlToPlatform = urlToPlatform;
app.config.globalProperties.formatNull = formatNull;
app.config.globalProperties.formatMoneyNum = formatMoneyNum;
app.config.globalProperties.atob = atob;
app.config.globalProperties.btoa = btoa;
app.config.globalProperties.sha256 = sha256;
app.config.globalProperties.postTypeToZn = postTypeToZn;

if (process.env.NODE_ENV  === "mock") {
  require("./mock");
}

// 全局组件
app.component("svg-icon", SvgIcon);
app.component("qr-code", qrCode);
app.mount("#app");
